@media only screen and (max-width: 600px) {
    .auth__method {
        margin-top: -15vh;
        margin-left: 0vw;
        width: 100vw;
    }
}

@media only screen and (min-width: 600px) {
    .auth__method {
        margin-top: -15vh;
        width: 100vw;
        
    }
}

@media only screen and (min-width: 768px) {
    .auth__method {
        margin-top: -15vh;
        width: 100vw;
        
    }
}

@media only screen and (min-width: 992px) {
    .auth__method {
        margin-top: -15vh;
        width: 100vw;
        
    }
}

@media only screen and (min-width: 1000px) {
    .auth__method {
        margin-top: -15vh;
        width: 50vw; 
    }
}
.common {
    background-color: #36393e;
    overflow-x: hidden;
}

.navbar {
    overflow-x: hidden;
} 

@media only screen and (max-width: 600px) {
    .navbar__mobileBox {
        width: 100vw;
        position: fixed;
        bottom: 0;
        z-index: 1;
    }

    .navbar {
        position: fixed;
        z-index: 1;
    
    }
}

@media only screen and (min-width: 600px) {
    .navbar__mobileBox {
        width: 100vw;
        position: fixed;
        bottom: 0;
        z-index: 1;
    }

    .navbar {
        position: fixed;
        z-index: 1;
    
    }
}

@media only screen and (min-width: 768px) {
    .navbar__mobileBox {
        width: 100vw;
        position: fixed;
        bottom: 0;
        z-index: 1;
    }

    .navbar {
        position: fixed;
        z-index: 1;
    
    }
}

@media only screen and (min-width: 992px) {
    .navbar__mobileBox {
        width: 100vw;
        position: fixed;
        bottom: 0;
        z-index: 1;
    }

    .navbar {
        position: fixed;
        z-index: 1;
    
    }
}

@media only screen and (min-width: 1200px) {
    .navbar__mobileBox {
        width: 100vw;
        position: fixed;
        bottom: 0;
        z-index: 1;
    }

    .navbar {
        position: fixed;
        z-index: 1;
    
    }
}

.common__appbar {
    background-color: #ff7f01 !important;
}


.common__logo {
    width: 80px;
}
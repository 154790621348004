.dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    overflow-x: hidden;
    margin-bottom: 10vh;
    margin-top: 10vh;
}



@media only screen and (max-width: 600px) {
    .dashboard__cards {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

@media only screen and (min-width: 600px) {
    .dashboard__cards {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

@media only screen and (min-width: 768px) {
    .dashboard__cards {
        display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: center;
        align-items: center;
    }
}

@media only screen and (min-width: 1200px) {
    .dashboard__cards {
        display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;
        align-items: center;
    }
}

@media only screen and (min-width: 1700px) {
    .dashboard__cards {
            display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.5vw;
    justify-content: center;
        align-items: center;
    }
}
.error__404 {
    background-color: #ff7f01;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.error_404Img {
    display: none;
}

.error__404Text {
    width: 80vw;
}

a:link {
    text-decoration: none;
  }
  
  a:visited {
    text-decoration: none;
  }


@media (min-width: 1000px) {
    .error_404Img {
        display: block;
        width: 50vw;
        height: 60vh;
    }

    .error__404Text {
        width: 20vw;
    }
}
.error__404TextTitle {
    font-size: 120px;
    font-weight: 1000;
    
    margin-bottom: 10vh;
}

.error__404TextSecondTitle {
    font-size: 35px;
    margin-top: -9vh;
    color:antiquewhite;
}

.error__404TextText {
    color: #fff;
    font-size: 25px;
    font-weight: 600;
}
@media only screen and (max-width: 999px) {
    .alege {
        margin-top: 5vh;
        display: flexbox;
        justify-content: center;
        align-items: center;
    }

    .alege__separator {
        display: none;
    }

    .alege__right {
        width: 100vw !important;
        margin-top: 10vh;
        display: flex;
    }

    .alege__left {
        width: 100vw !important;
    }

    .alege__form {
        height: 35vh;
        width: 100vw !important;
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .alege__alert {
        margin-bottom: 5vh;
        width: 100vw !important;
        justify-self: center;
        justify-content: center;
        display: flex;
    
    }

    .alege__accordion {
        margin-top: 5vh;
        width: 100vw !important;
    }

    .alege__rightImage {
        width: 100vw !important;
    }
}
@media only screen and (min-width: 1000px) {
    .alege {
        margin-top: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.alege__separator {
    height: 70vh;
    width: 5px;
    border-radius: 10px;
    background-color: gray;
    margin: 2vw;
}

.alege__left {
    width: 40vw;
}

.alege__right {
    width: 40vw;
    display: flex;
    align-items: center;
}

.alege__rightImage {
    width: 40vw;
    margin-left: 4vw;
}

.alege__form {
    height: 35vh;
    width: 30vw;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.alege__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30vw;
    gap: 30px;
}

.alege__slider {
    width: 20vw;
}

.alege__sliderText {
    float: left;
}

.alege__title {
    width: 20vw;
}

.alege__accordion {
    margin-top: 5vh;
    width: 30vw;
}

.alege__alert {
    margin-bottom: 5vh;
    width: 28.5vw;
    justify-self: center;
    justify-content: center;
    display: flex;

}

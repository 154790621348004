.card {
    width: 350px;
    height: 240px;
    box-shadow: 4.0px 8.0px 8.0px hsl(0deg 0% 0% / 0.38);
    display: block;
    border-radius: 10px;
    background-color: #282b30;
    margin-bottom: 20px;

}

.card__coverImg {
    height: 240px;
    width: 500px;
    z-index: 99;
    opacity: 1;
    transition: .5s ease;
    background-color: #282b30;
}

.card__header {
    height: 40px;
    background-color: #282b30;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -250px;
}

.card__content {
    font-size: 15px;
    width: 350px;
    height: 30px;
    text-align: left;
    font-weight: 800;
    background-color: #282b30;

}

a {
    color: white;
}

.card__difficulty {
    font-size: 15px;
    justify-content: center;
    align-items: center;
}

.card__rating {
    margin-top: -4vh;
}

.card__description {
    margin-top: -1.5vh;
}
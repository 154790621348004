.landing {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    overflow-x: hidden;
    background-color: #fff;
}

:root{
  --color-fg: #fff;
  --color-bg: #282b30;
  --color1: #ff7f01;
  --color2: #0082a5;
  --color3: #ffa901;
  --color4: #fbc200;
}


/* --------------navigatie------------- */



.headerh{
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(rgba(4,9,30,0.7),rgba(4,9,30,0.7)),url(./resources/images/home.jpg);
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.headera{
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(rgba(4,9,30,0.7),rgba(4,9,30,0.7)),url(./resources/images/about.jpg);
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}


.headerc{
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(rgba(4,9,30,0.7),rgba(4,9,30,0.7)),url(./resources/images/contact.jpg);
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}



nav{
  display: flex;
  padding: 2% 6%;
  justify-content: space-between;
  align-items: center;
}

nav img{
  width: 150px;
}

.nav_links{
  flex: 1;
  text-align: right;
}

.nav_links ul li{
  list-style: none;
  display: inline-block;
  padding: 8px 12px;
  position: relative;

}

.nav_links ul li a{
  color: var(--color-fg);
  text-decoration: none;
  font-size: 13px;
}

.nav_links ul li a:hover{
 color: var(--color2);
 transition: 0.5s;

}

.nav_links ul li::after{
  content: '';
  width: 0%;
  height: 2px;
  background: var(--color1);
  display: block;
  margin: auto;
  transition: 0.5s;
}

.nav_links ul li:hover::after{
  width: 100%;
}

.text_box{
  width: 90%;
  color: var(--color-fg);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
}


/* --------------prima pag welcome cover------------- */



.text_box h1{
  font-size: 62px;
}

.text_box p{
  margin: 10px 0 40px;
  font-size: 14px;
  color: var(--color-fg);
}

.buttonstart{
  border-radius: 10px;
  display: inline-block;
  text-decoration: none;
  color: var(--color-fg);
  border: 1px solid var(--color2);
  padding: 12px 34px;
  font-size: 13px;
  background: transparent;
  position: relative;
  cursor: pointer;
}


.buttonstart:hover{
  border: 1px solid var(--color1);
  background: var(--color1);
  transition: 0.5s;
}



/* --------------Content------------- */


span.nobr { white-space: nowrap; }  /* ca sa nu se desparta prost */


.content{
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 100px;

}

h1{
  font-size: 36px;
  font-weight: 600;
}

h3{
  text-align: center;
  font-weight: 600;
  margin: 10px 0;
  color: var(--color-bg);
}

p{
  color: var(--color-bg);
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  padding: 10px;
}

.row{
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
}

.column-cont{
  border: 1px solid var(--color3);
  flex-basis: 31%;
  background: #FFCBA2;
  border-radius: 10px;
  margin-bottom: 5%;
  padding: 20px 12px;
  box-sizing: border-box;
  transition: 0.5s;
}

.column-cont:hover{
box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}

/* --------------Review------------- */

.review{
  width: 80%;
  margin: auto;
  padding-top: 100px;
  text-align: center;
}

.reviewcard{
  flex-basis: 44%;
  border-radius: 10px;
  margin-bottom: 5%;
  text-align: left;
  background: var(--color3);
  padding: 25px;
  cursor: pointer;
}

.imgr{
  height: 60px;
  width: 60px;
  margin-left: 5px;
  margin-right: 30px;
  border-radius: 50%;
}




/* --------------Citat------------- */

.citat{
  margin: 100px auto;
  width: 80%;
  background-image: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url(./resources/images/map.png);
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  text-align: center;
  padding: 30px 0;
  transition: 0.5s;
}

.citat {
  margin-bottom: 100px;
  padding: 0;
}
  
.citat:hover{
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}

.citattext{
  color: var(--color-fg);
}


/* --------------Footer------------- */

.footer ul li{
  list-style: none;
  display: inline-block;
  padding: 8px 12px;
  position: relative;
  align-items: center;
}

.footer{
  flex: 1;
  text-align: center;
}

.footer ul li a{
  color: var(--color-bg);
  text-decoration: none;
  font-size: 13px;
}

.footer ul li::after{
  content: '';
  width: 0%;
  height: 2px;
  background: var(--color1);
  display: block;
  margin: auto;
  transition: 0.5s;
}

.footer ul li a:hover{
  color: var(--color2);
  transition: 0.5s;
 }

 .footer ul li:hover::after{
  width: 100%;
}

.icons li a{
  color: var(--color-fg);
  text-decoration: none;
}

.icons li{
  list-style: none;
}


/* --------------display mic randare------------- */




nav .fa{
  display: none;
}

@media(max-width: 700px){
    /* --hamburger meniu */

    

    .text_box h1{
      font-size: 20px;
    }
    .nav_links ul li{
      display: block;
    }
    .nav_links{
      position: absolute;
      background: var(--color1);
      height: 200vh;
      width: 200px;
      top: 0;
      right: -200px;
      text-align: left;
      z-index: 2;
      transition: 0.8s;
    }
    nav .fa{
      display: block;
      color: var(--color-fg);
      margin: 10px;
      font-size: 20px;
      cursor: pointer;
    }
    .nav_links ul{
      padding: 30px;
    }

    /* --content */

    .row{
      flex-direction: column;
    }




}






